import React, { Fragment, useContext, useRef, useState, useEffect, useCallback, useMemo } from 'react'
import { Empty, Table, Typography, Button, Switch, Dropdown, Menu, Modal, Icon, Spin, Tag, Tooltip, notification } from 'antd'
import { ExclamationCircleOutlined, ClockCircleTwoTone } from '@ant-design/icons'
import { EnvStateContext } from 'helpers/contexts'
import { getUniqueTags } from 'helpers'
import { useItemFilters, useReducerState } from 'hooks'
import moment from 'moment-timezone'
import request from 'request'

import { Box } from 'components/layout'
import HistoryModal from 'components/modals/HistoryModal/HistoryModal'

import CreateModal from './CreateModal/CreateModal'
import ScheduleCalendar from '../../components/ScheduleCalendar/ScheduleCalendar'


const SwitchCell = ({ enabled, onChange }) => {
  const [ isLoading, setLoading ] = useState(false)

  useEffect(() => {
    setLoading(false)
  }, [ enabled ])

  const handleChange = useCallback((value) => {
    setLoading(true)
    onChange(value)
  }, [])

  return (
    <Switch
      checked={enabled}
      size="small"
      disabled={isLoading}
      loading={isLoading}
      onChange={handleChange}
    />
  )
}

const FeatureTogglesPage = () => {
  const env = useContext(EnvStateContext)

  const lastUpdatedItem = useRef(null)
  const [ state, setState ] = useReducerState({ isLoading: false, items: [], tags: [] })
  const [ createModalState, setCreateModalState ] = useState({ visible: false, data: null })
  const [ scheduleModalState, setScheduleModalState ] = useState({ visible: false, data: null })
  const [ historyModalState, setHistoryModalState ] = useState({ visible: false, endpoint: null })
  const { isLoading, items, tags } = state

  const loadFeatures = () => {
    setState({ isLoading: true, items: [], tags: [] })

    request.get(`/rest/features?env=${env}&verbose=true`)
      .then(({ data: { items } }) => {
        setState({
          isLoading: false,
          items: items.map((item) => ({
            key: item.name,
            ...item,
          })),
          tags: getUniqueTags(items),
        })
      })
  }

  useEffect(loadFeatures, [ env ])

  const handleCreateClick = useCallback(() => {
    setCreateModalState({ visible: true })
  }, [])

  const { itemFiltersNode, searchQuery, filterTags, isItemTagsVisible } = useItemFilters({ tags, onCreateClick: handleCreateClick })

  const update = useCallback((promise) => (
    promise
      .then(({ data: updatedItem }) => {
        notification.success({
          message: 'Feature Toggle updated successfully!',
        })

        setState(({ items }) => {
          const newItems = items.map((item) => {
            if (item.id === updatedItem.id) {
              return updatedItem
            }

            return item
          })

          const newTags = getUniqueTags(newItems)

          return { isLoading: false, items: newItems, tags: newTags }
        })
      }, (err) => {
        console.log(err)
        notification.error({
          message: 'Something went wrong!',
          description: err,
        })
      })
  ), [])

  const handleChangeEnableStatus = useCallback(({ id, enabled }) => {
    update(request.patch(`/rest/features/${id}/state?env=${env}`, { enabled }))
  }, [ env ])

  const handleSubmitModal = useCallback((id, values) => {
    if (id) {
      update(request.patch(`/rest/features/${id}?env=${env}`, values))
        .then(() => {
          setCreateModalState({ visible: false })
        })
    }
    else {
      request.post('/rest/features', values)
        .then(({ data: item }) => {
          notification.success({ message: 'Feature Toggle created successfully!' })
          setCreateModalState({ visible: false })

          setState(({ items }) => {
            const newItems = [ item, ...items ]
            const newTags = getUniqueTags(newItems)

            return { isLoading: false, items: newItems, tags: newTags }
          })
        }, (err) => {
          console.log(err)
          notification.error({ message: 'Something went wrong!' })
        })
    }
  }, [ env ])

  const handleScheduleSubmit = useCallback((id, enableDate, disableDate) => {
    request.post(`/rest/features/${id}/schedule`, { env, enableDate, disableDate })
      .then(({ data }) => {
        notification.success({ message: `Feature Toggle scheduled actions successfully applied!` })
        setScheduleModalState({ visible: false })
        loadFeatures()
      }, (err) => {
        console.log(err)
        notification.error({ message: 'Something went wrong!' })
      })
  }, [ env ])

  const handleCancelModal = useCallback(() => {
    setCreateModalState({ visible: false })
  }, [])

  const handleCloseHistoryModal = useCallback(() => {
    setHistoryModalState({ visible: false })
  }, [])

  const handleScheduleCancel = useCallback(() => {
    setScheduleModalState({ visible: false })
  }, [])

  const handleRemoveItem = useCallback((id) => {
    setState({ isLoading: true })

    request.delete(`/rest/features/${id}`)
      .then(() => {
        notification.success({ message: 'Feature Toggle removed successfully!' })

        setState(({ items }) => {
          const newItems = items.filter((item) => item.id !== id)
          const newTags = getUniqueTags(newItems)

          return { isLoading: false, items: newItems, tags: newTags }
        })
      }, (err) => {
        console.log(err)
        notification.error({ message: 'Something went wrong!' })
        setState({ isLoading: false })
      })
  }, [])

  const filteredItems = useMemo(() => {
    if (!searchQuery && !filterTags.length) {
      return items
    }

    return items.filter(({ name, tags }) => {
      const matchByQuery = !searchQuery || name.toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0

      if (!matchByQuery) {
        return false
      }

      return filterTags.every((tag) => tags.includes(tag))
    })
  }, [ items, searchQuery, filterTags ])

  const columns = useMemo(() => [
    {
      key: 'status',
      title: '',
      dataIndex: 'enabled',
      width: '1%',
      render: (_, record) => {
        const { id, enabled } = record

        const handleChange = (value) => {
          handleChangeEnableStatus({ id, enabled: value })
        }

        return (
          <div onClick={(event) => event.stopPropagation()}>
            <SwitchCell enabled={enabled} onChange={handleChange} />
          </div>
        )
      },
    },
    {
      key: 'name',
      title: 'Name',
      dataIndex: 'name',
      render: (_, record) => {
        const { name, issue, description, tags, scheduledEnableAt, scheduledDisableAt } = record

        const handleScheduleClick = (event) => {
          setScheduleModalState({ visible: true, data: record })
        }

        const enableTooltipTitle = scheduledEnableAt
          ? `enabling is scheduled for ${moment(scheduledEnableAt).format('MM/DD/YYYY HH:mm:ss')} (Your local time); `
          + `${moment(scheduledEnableAt).tz('America/New_York').format('MM/DD/YYYY HH:mm:ss (z)')}` : null

        const disableTooltipTitle = scheduledDisableAt
          ? `disabling is scheduled for ${moment(scheduledDisableAt).format('MM/DD/YYYY HH:mm:ss')} (Your local time); `
          + `${moment(scheduledDisableAt).tz('America/New_York').format('MM/DD/YYYY HH:mm:ss (z)')}` : null

        const issueKey = issue.replace(/.+\//, '')
        const issueLink = `https://scentbird.atlassian.net/browse/${issueKey}`

        return (
          <Fragment>
            <Typography.Text strong>{name}</Typography.Text>&nbsp;&nbsp;&nbsp;
            {
              issueKey && (
                <a href={issueLink} target="_blank" onClick={(event) => event.stopPropagation()} rel="noreferrer">{issueKey}</a>
              )
            }
            {
              description && (
                <Box p="1 0 0">
                  <Typography.Text type="secondary">{description}</Typography.Text>
                </Box>
              )
            }
            {
              (scheduledEnableAt || scheduledDisableAt) && (
                <Box p="1 0 0">
                  {
                    scheduledEnableAt && (
                      <Tooltip placement="bottomLeft" title={enableTooltipTitle} onClick={handleScheduleClick}>
                        <ClockCircleTwoTone twoToneColor="#52c41a" style={{ marginRight: '5px' }} />
                      </Tooltip>
                    )
                  }
                  {
                    scheduledDisableAt && (
                      <Tooltip placement="bottomLeft" title={disableTooltipTitle} onClick={handleScheduleClick}>
                        <ClockCircleTwoTone twoToneColor="#eb2f96" />
                      </Tooltip>
                    )
                  }
                </Box>
              )
            }
            {
              Boolean(isItemTagsVisible && tags.length) && (
                <Box p="1 0 0">
                  {
                    tags.map((tag) => (
                      <Tag key={tag}>{tag}</Tag>
                    ))
                  }
                </Box>
              )
            }
          </Fragment>
        )
      },
    },
    {
      key: 'enabledAt',
      title: 'Start',
      dataIndex: 'stateUpdatedAt', // when enabled
      align: 'center',
      render: (value, record) => {
        if (!record.enabled) {
          return null
        }

        return (
          <div title={moment(value).format('MM/DD/YYYY HH:mm:ss')} style={{ whiteSpace: 'nowrap' }}>
            {moment(value).fromNow()}
          </div>
        )
      },
    },
    {
      key: 'menu',
      width: '1%',
      render: (_, record) => {
        const handleMenuClick = ({ key, item }) => {
          if (key === 'edit') {
            setCreateModalState({ visible: true, data: record })
          }
          else if (key === 'schedule') {
            setScheduleModalState({ visible: true, data: record })
          }
          else if (key === 'history') {
            setHistoryModalState({ visible: true, endpoint: `/rest/features/${record.id}/history` })
          }
          else if (key === 'delete') {
            Modal.confirm({
              title: 'Are you sure delete this task?',
              icon: <ExclamationCircleOutlined />,
              content: 'Some descriptions',
              okText: 'Yes',
              okType: 'danger',
              cancelText: 'No',
              onOk() {
                handleRemoveItem(record.id)
              },
              onCancel() { },
            })
          }
        }

        const menu = (
          <Menu onClick={handleMenuClick}>
            <Menu.Item key="edit">Edit</Menu.Item>
            <Menu.Item key="history">History</Menu.Item>
            <Menu.Item key="schedule">Schedule</Menu.Item>
            <Menu.Item key="delete"><span style={{ color: 'red' }}>Delete</span></Menu.Item>
          </Menu>
        )

        return (
          <Dropdown overlay={menu}>
            <Button size="small">
              <Icon type="ellipsis" />
            </Button>
          </Dropdown>
        )
      },
    },
  ], [ env, isItemTagsVisible ])

  return (
    <Fragment>
      {itemFiltersNode}
      {
        isLoading ? (
          <div style={{ padding: '30px 0', textAlign: 'center' }}>
            <Spin size="large" />
          </div>
        ) : (
          Boolean(filteredItems.length) ? (
            <Table
              columns={columns}
              dataSource={filteredItems}
            />
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )
        )
      }
      {
        createModalState.visible && (
          <CreateModal
            tags={tags}
            data={createModalState.data}
            onSubmit={handleSubmitModal}
            onCancel={handleCancelModal}
          />
        )
      }
      {
        historyModalState.visible && (
          <HistoryModal
            endpoint={historyModalState.endpoint}
            onClose={handleCloseHistoryModal}
          />
        )
      }
      {
        scheduleModalState.visible && (
          <ScheduleCalendar
            data={scheduleModalState.data}
            onSubmit={handleScheduleSubmit}
            onCancel={handleScheduleCancel}
          />
        )
      }
    </Fragment>
  )
}


export default FeatureTogglesPage
