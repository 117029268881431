const isEmpty = (value) => typeof value === 'undefined' || value === null || value === '' || /^\s+$/.test(value)

export const required = (value) => {
  if (isEmpty(value)) {
    return 'Required'
  }
}

export const startsWithCharacter = (value) => {
  if (!isEmpty(value) && !/[a-zA-Z]/.test(value[0])) {
    return 'Must starts with character'
  }
}
