import React, { useMemo, useCallback } from 'react'
import { Form as Formular } from 'formular'
import { Button, notification } from 'antd'
import request from 'request'

import { Flex, Box } from 'components/layout'
import { Input, Select } from 'components/form'


const BatchRemovePage = () => {
  const form = useMemo(() => new Formular({
    fields: {
      names: [],
    },
  }), [])

  const handleSubmit = useCallback((event) => {
    event.preventDefault()

    form.submit()
      .then(() => {
        const values = form.getValues()
        const names = values.names.trim().split(/\s*,\s*/)

        request.delete('/rest/features/batch', {
          data: {
            names,
          },
        })
          .then(() => {
            notification.success({ message: 'Feature Toggles removed successfully!' })
          }, (err) => {
            console.log(err)
            notification.error({ message: 'Something went wrong!' })
          })
      })
  }, [])

  return (
    <form onSubmit={handleSubmit}>
      <Flex>
        <Input
          field={form.fields.names}
          placeholder={`Feature names (use ",")`}
        />
        <Box p="0 0 0 2">
          <Button
            type="danger"
            size="large"
            htmlType="submit"
          >
            Remove
          </Button>
        </Box>
      </Flex>
    </form>
  )
}


export default BatchRemovePage
