import React, { useContext, useState, useEffect } from 'react'
import { EnvStateContext } from 'helpers/contexts'
import request from 'request'
import { Modal } from 'antd'

import History from 'components/History/History'


const HistoryModal = ({ endpoint, onClose }) => {
  const env = useContext(EnvStateContext)
  const [ { isFetching, items }, setState ] = useState({ isFetching: true, items: [] })

  useEffect(() => {
    request.get(endpoint, {
      params: {
        env,
      },
    })
      .then(({ data: { items } }) => {
        setState({ items, isFetching: false })
      }, () => {
        setState({ items: [], isFetching: false })
      })
  }, [ endpoint ])

  return (
    <Modal
      title="History"
      visible
      width={900}
      okText="Submit"
      onOk={onClose}
      onCancel={onClose}
    >
      <History {...{ isFetching, items }} />
    </Modal>
  )
}


export default HistoryModal
