import React, { Fragment, useContext, useState, useCallback, useEffect, useMemo } from 'react'
import { Input, Select, Icon, Pagination } from 'antd'
import request from 'request'

import { Flex, Box } from 'components/layout'
import WebhooksTable from 'components/WebhooksTable/WebhooksTable'


const WebhooksPage = () => {
  const [ { items }, setState ] = useState({ items: [] })

  useEffect(() => {
    request.get('/rest/webhooks')
      .then(({ data: { items } }) => {
        setState({ items })
      }, () => {
        setState({ items: [] })
      })
  }, [])

  return (
    <Fragment>
      <WebhooksTable items={items} />
    </Fragment>
  )
}


export default WebhooksPage
