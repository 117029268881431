import React, { useMemo } from 'react'
import { Empty, Spin, Table, Tag } from 'antd'
import { Flex, Box } from 'components/layout'
import { Link } from 'react-router-dom'
import moment from 'moment-timezone'


const WebhooksHistory = ({ items }) => {
  const columns = useMemo(() => [
    {
      key: 'url',
      title: 'URL',
      width: '90%',
      render: (_, { id, url }) => (
        <Link to={`/webhooks/${id}/history`}>{url}</Link>
      ),
    },
    {
      key: 'enabled',
      title: 'Enabled',
      width: '5%',
      render: (_, { enabled }) => (
        <Tag color={enabled ? 'green' : 'volcano'}>{Boolean(enabled).toString()}</Tag>
      ),
    },
    {
      key: 'date',
      title: 'When',
      width: '1%',
      align: 'right',
      render: (_, { createdAt }) => (
        <div title={moment(createdAt).format('MM/DD/YYYY HH:mm:ss')} style={{ whiteSpace: 'nowrap' }}>
          {moment(createdAt).fromNow()}
        </div>
      ),
    },
  ], [])

  return (
    <Box p="2 0 5">
      {
        Boolean(items.length) ? (
          <Table columns={columns} rowKey={(record) => record.id} dataSource={items} pagination={false} />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )
      }
    </Box>
  )
}


export default WebhooksHistory
