import React, { Fragment, useContext, useState, useCallback, useEffect, useMemo } from 'react'
import { EnvStateContext } from 'helpers/contexts'
import { Input, Select, Icon } from 'antd'
import request from 'request'

import { Flex, Box } from 'components/layout'
import Schedule from 'components/Schedule/Schedule'


const SchedulePage = () => {
  const env = useContext(EnvStateContext)
  const [ { isFetching, items }, setState ] = useState({ isFetching: true, items: [] })
  const [ searchQuery, setSearchQuery ] = useState('')

  useEffect(() => {
    setState({ isFetching: true, items: [] })

    request.get('/rest/schedule', {
      params: {
        env,
      },
    })
      .then(({ data: { items } }) => {
        setState({ items, isFetching: false })
      }, () => {
        setState({ items: [], isFetching: false })
      })
  }, [ env ])

  const handleSearchChange = useCallback((event) => {
    setSearchQuery(event.target.value)
  }, [])

  const filteredItems = useMemo(() => {
    if (!searchQuery) {
      return items
    }

    return items.filter(({ featureName, subject }) => (
      featureName
      && (!searchQuery || featureName.toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0)
    ))
  }, [ items, searchQuery ])

  return (
    <Fragment>
      <Box p="0 0 2">
        <Flex alignCenter>
          <Input
            placeholder="Filter"
            prefix={<Icon type="filter" style={{ color: 'rgba(0,0,0,.25)' }} />}
            size="large"
            onChange={handleSearchChange}
          />
        </Flex>
      </Box>
      <Schedule {...{ isFetching, items: filteredItems }} />
    </Fragment>
  )
}


export default SchedulePage
