import React, { useRef, useState, useCallback, Fragment } from 'react'
import { Modal, DatePicker, Typography } from 'antd'
// import moment from 'moment'
import moment from 'moment-timezone'

import { Box } from 'components/layout'


const ScheduleCalendar = ({ data, onSubmit, onCancel }) => {
  const [ error, setError ] = useState(null)
  const [ enableDate, setEnableDate ] = useState(data.scheduledEnableAt ? moment(data.scheduledEnableAt) : null)
  const [ disableDate, setDisableDate ] = useState(data.scheduledDisableAt ? moment(data.scheduledDisableAt) : null)


  const handleEnableDatePick = useCallback((date, dateString) => {
    setEnableDate(date)
  }, [])

  const handleDisableDatePick = useCallback((date, dateString) => {
    setDisableDate(date)
  }, [])

  const handleSubmit = useCallback(() => {
    // if (selectedEnableDate.current || selectedDisableDate.current) {
    onSubmit(data.id, enableDate, disableDate)
    // }
    // else {
    //   setError('At least one date should be selected!')
    // }
  }, [ enableDate, disableDate ])

  const handleCancel = useCallback(() => {
    onCancel()
  }, [])

  return (
    <Modal
      title={`Select when ${data.name} should be enabled or/and disabled`}
      visible
      okText="Submit"
      cancelText="Cancel"
      onOk={handleSubmit}
      onCancel={handleCancel}
    >
      <Box p="0 0 1">
        <Typography.Text strong>Feature toggle will be enabled on:</Typography.Text>
      </Box>
      <DatePicker
        format="YYYY-MM-DD HH:mm:ss"
        value={enableDate}
        showTime
        disabledDate={(current) => current && current < moment()}
        style={{ width: 210 }}
        onChange={handleEnableDatePick}
      />
      <Box p="1 0 1">
        {
          enableDate !== null
          && <Fragment>
            <Typography.Paragraph>
              <Typography.Text>{moment(enableDate).format('MM/DD/YYYY HH:mm:ss')} (Your local time)</Typography.Text><br />
              <Typography.Text>{moment(enableDate).tz('America/New_York').format('MM/DD/YYYY HH:mm:ss (z)')}</Typography.Text>
            </Typography.Paragraph>
          </Fragment>
        }
      </Box>
      <Box p="0 0 1">
        <Typography.Text strong>Feature toggle will be disabled on:</Typography.Text>
      </Box>
      <DatePicker
        format="YYYY-MM-DD HH:mm:ss"
        value={disableDate}
        showTime
        disabledDate={(current) => current && current < moment()}
        style={{ width: 210 }}
        onChange={handleDisableDatePick}
      />
      <Box p="1 0 1">
        {
          disableDate !== null
          && <Fragment>
            <Typography.Paragraph>
              <Typography.Text>{moment(disableDate).format('MM/DD/YYYY HH:mm:ss')} (Your local time)</Typography.Text><br />
              <Typography.Text>{moment(disableDate).tz('America/New_York').format('MM/DD/YYYY HH:mm:ss (z)')}</Typography.Text>
            </Typography.Paragraph>
          </Fragment>
        }
      </Box>
      {
        Boolean(error) && (
          <Box p="2 0 0">
            <Typography.Text type="danger">{error}</Typography.Text>
          </Box>
        )
      }
    </Modal>
  )
}


export default ScheduleCalendar
