import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import App from 'containers/App/App'

import FeatureTogglesPage from 'pages/FeatureTogglesPage/FeatureTogglesPage'
import HistoryPage from 'pages/HistoryPage/HistoryPage'
import BatchRemovePage from 'pages/BatchRemovePage/BatchRemovePage'
import WebhooksPage from 'pages/WebhooksPage/WebhooksPage'
import WebhooksHistoryPage from 'pages/WebhooksHistoryPage/WebhooksHistoryPage'
import SchedulePage from 'pages/SchedulePage/SchedulePage'


const routes = (
  <App>
    <Route path="/" exact render={() => <Redirect to="/features" />} />
    <Route path="/features" exact component={FeatureTogglesPage} />
    <Route path="/history" exact component={HistoryPage} />
    <Route path="/features/batch-remove" exact component={BatchRemovePage} />
    <Route path="/webhooks" exact component={WebhooksPage} />
    <Route path="/webhooks/:id/history" component={WebhooksHistoryPage} />
    <Route path="/schedule" component={SchedulePage} />
  </App>
)


export default routes
