import React, { useContext, useCallback } from 'react'
import { EnvStateContext, EnvDispatchContext } from 'helpers/contexts'
import { Select, Radio, Button, Menu, Dropdown, DownOutlined } from 'antd'
import cx from 'classnames'

import WidthContainer from 'components/layout/WidthContainer/WidthContainer'

import s from './EnvSwitcher.scss'


const EnvSwitcher = ({ envs = [] }) => {
  const env = useContext(EnvStateContext)
  const setEnv = useContext(EnvDispatchContext)

  const handleChange = useCallback((e) => {
    const value = e.target ? e.target.value : e.key
    globals.env = value
    localStorage.setItem('env', value)
    setEnv(value)
  }, [])

  const mainEnvironmentsDict = {
    dev: 'Dev',
    stage: 'Stage',
    prod: 'Production',
  }

  let text = mainEnvironmentsDict[env] || env

  const menu = (
    <Menu onClick={handleChange}>
      {
        envs.map((item) =>
          <Menu.Item key={item} value={env} onClick={handleChange}>{mainEnvironmentsDict[item] || item}</Menu.Item>
        )
      }
    </Menu>
  )

  return (
    <div className={s.container}>
      <WidthContainer>
        <div className={cx(s.content, s[env])}>
          <div className={s.text}>{text}</div>
          <Radio.Group value={env} onChange={handleChange}>
            {
              Object.keys(mainEnvironmentsDict).map((item) =>
                <Radio.Button value={item} key={item}>{mainEnvironmentsDict[item] || item}</Radio.Button>
              )
            }
            {envs.length > 3
              && <Dropdown overlay={menu}>
                <Button>...</Button>
              </Dropdown>
            }
          </Radio.Group>
        </div>
      </WidthContainer>
    </div>
  )
}


export default EnvSwitcher
