import React, { useMemo } from 'react'
import { Empty, Spin, Table, Tag, Tabs } from 'antd'

import { Flex, Box } from 'components/layout'

import moment from 'moment-timezone'


const { TabPane } = Tabs

const renderJsonFields = (content) =>
  Object.keys(content).map((key) => <p key={key}><strong>{key}</strong>: {JSON.stringify(content[key])}</p>)

const WebhooksHistory = ({ isFetching, items }) => {
  const columns = useMemo(() => [
    {
      key: 'id',
      title: 'id',
      width: '90%',
      render: (_, { id }) => {
        return (
          <div style={{ whiteSpace: 'nowrap' }}>{id}</div>
        )
      },
    },
    {
      key: 'responseCode',
      title: 'Response code',
      width: '5%',
      render: (_, { responseCode }) => (
        <Tag color={responseCode === 200 ? 'green' : 'volcano'}>{responseCode}</Tag>
      ),
    },
    {
      key: 'date',
      title: 'When',
      width: '5%',
      align: 'right',
      render: (_, { createdAt }) => (
        <div title={moment(createdAt).format('MM/DD/YYYY HH:mm:ss')} style={{ whiteSpace: 'nowrap' }}>
          {moment(createdAt).fromNow()}
        </div>
      ),
    },
  ], [])

  const expandedRowRender = ({ requestHeaders, requestBody, responseHeaders, responseBody }, id) => {
    return (
      <Tabs type="card" key={id}>
        {
          requestHeaders
          && <TabPane tab="Request headers" key="1">
            <div>{renderJsonFields(requestHeaders)}</div>
          </TabPane>
        }
        {
          requestBody
          && <TabPane tab="Request body" key="2">
            <div>{renderJsonFields(requestBody)}</div>
          </TabPane>
        }
        {
          responseHeaders
          && <TabPane tab="Response headers" key="3">
            <div>{renderJsonFields(responseHeaders)}</div>
          </TabPane>
        }
        {
          responseBody
          && <TabPane tab="Response body" key="4">
            <div>{renderJsonFields(responseBody)}</div>
          </TabPane>
        }
      </Tabs>
    )
  }


  return (
    <div>
      {
        isFetching ? (
          <div style={{ padding: '30px 0', textAlign: 'center' }}>
            <Spin size="large" />
          </div>
        ) : (
          Boolean(items.length) ? (
            <Table
              columns={columns}
              expandedRowRender={expandedRowRender}
              rowKey={(record) => record.id}
              dataSource={items}
              pagination={false} />
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )
        )
      }
    </div>
  )
}


export default WebhooksHistory
