import React, { Fragment, useState, useCallback, useEffect } from 'react'
import { Input as AntInput } from 'antd'
import { FieldError } from 'components/form'


const Input = ({ field, multiline, ...rest }) => {
  const [ _, forceRefresh ] = useState(0)

  useEffect(() => {
    const refresh = () => {
      forceRefresh((v) => ++v)
    }

    // field.on('change', refresh)
    field.on('validate', refresh)

    return () => {
      // field.off('change', refresh)
      field.off('validate', refresh)
    }
  }, [ field ])

  const handleChange = useCallback((event) => {
    field.set(event.target.value)
  }, [])

  let component = AntInput

  if (multiline) {
    component = AntInput.TextArea
  }

  const node = React.createElement(component, {
    size: 'large',
    defaultValue: field.state.value,
    onChange: handleChange,
    ...rest,
  })

  return (
    <Fragment>
      {node}
      {
        Boolean(field.state) && Boolean(field.state.error) && (
          <FieldError>{field.state.error}</FieldError>
        )
      }
    </Fragment>
  )
}


export default Input
