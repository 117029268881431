import React from 'react'
import cx from 'classnames'

import s from './Flex.scss'


const Flex = ({ children, flexStart, flexEnd, alignCenter }) => {
  const className = cx(s.flex, {
    [s.flexStart]: flexStart,
    [s.flexEnd]: flexEnd,
    [s.alignCenter]: alignCenter,
  })

  return (
    <div className={className}>
      {children}
    </div>
  )
}


export default Flex
