// eslint-disable-next-line import/no-mutable-exports
let sentry

if (process.env.NODE_ENV !== 'development' && globals.sentryDsn) {
  sentry = require('@sentry/browser') // eslint-disable-line

  sentry.init({
    dsn: globals.sentryDsn,
    environment: globals.runEnv,
  })
}
else {
  sentry = {
    withScope: (fn) => {
      const scope = {
        setUser: (user) => scope.user = user,
        setTag: (key, value) => scope[key] = value,
        setExtras: (extras) => scope.extras = extras,
      }
      sentry.scope = scope

      fn(scope)
    },
    scope: {},
    getScope: () => {
      const { scope } = sentry
      sentry.scope = {}

      return scope
    },
    captureException: (err) => console.log('Sentry.captureException', err, sentry.getScope()),
    captureMessage: (message) => console.log('Sentry.captureMessage', message, sentry.getScope()),
  }
}


export default sentry
