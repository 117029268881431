import React, { useMemo } from 'react'
import { Empty, Spin, Table, Tag, Icon } from 'antd'
import { FieldTimeOutlined } from '@ant-design/icons'
import moment from 'moment-timezone'


const History = ({ isFetching, items }) => {
  const columns = useMemo(() => [
    {
      key: 'entity',
      title: 'Entity',
      width: '5%',
      render: (_, { subject }) => {

        if (subject === 'feature') {
          return <Icon type="flag" />
        }
        if (subject === 'user') {
          return <Icon type="user" />
        }
        if (subject === 'env') {
          return <Icon type="environment" />
        }
        if (subject === 'schedule') {
          return <FieldTimeOutlined />
        }
        return null
      },
    },
    {
      key: 'name',
      title: '',
      width: '10%',
      render: (_, record) => (
        <b>{record.entityName}</b>
      ),
    },
    {
      key: 'action',
      title: 'Action',
      align: 'center',
      render: (_, { action, values }) => {
        let message
        let color

        if (action === 'create') {
          message = 'Create'
          color = 'geekblue'
        }
        else if (action === 'delete') {
          message = 'Delete'
          color = 'magenta'
        }
        else if (action === 'update' && 'enabled' in values) {
          message = values.enabled ? 'Enable' : 'Disable'
          color = values.enabled ? 'green' : 'volcano'
        }
        else if (action === 'update' && 'value' in values) {
          message = values.value.toUpperCase()
          color = 'blue'
        }
        else {
          message = action
          color = null
        }

        return (
          <Tag color={color}>{message}</Tag>
        )
      },
    },
    {
      key: 'owner',
      title: 'Who',
      width: '10%',
      align: 'right',
      render: (_, { creator: { name, email } }) => (
        <div>
          <div>{name}</div>
          <span style={{ color: '#aaa', fontSize: '12px' }}>{email}</span>
        </div>
      ),
    },
    {
      key: 'date',
      title: 'When',
      width: '10%',
      align: 'right',
      render: (_, { createdAt }) => (
        <div title={moment(createdAt).format('MM/DD/YYYY HH:mm:ss')} style={{ whiteSpace: 'nowrap' }}>
          {moment(createdAt).fromNow()}
        </div>
      ),
    },
  ], [])

  return (
    <div>
      {
        isFetching ? (
          <div style={{ padding: '30px 0', textAlign: 'center' }}>
            <Spin size="large" />
          </div>
        ) : (
          Boolean(items.length) ? (
            <Table columns={columns} dataSource={items} />
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )
        )
      }
    </div>
  )
}


export default History
