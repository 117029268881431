import React, { useState } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { EnvStateContext, EnvDispatchContext } from 'helpers/contexts'


const Root = ({ routes }) => {
  const [ env, setEnv ] = useState(globals.env)

  return (
    <Router>
      <EnvStateContext.Provider value={env}>
        <EnvDispatchContext.Provider value={setEnv}>
          {routes}
        </EnvDispatchContext.Provider>
      </EnvStateContext.Provider>
    </Router>
  )
}


export default Root
