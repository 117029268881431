import React from 'react'


const Box = ({ children, p, style = {} }) => {
  const padding = p ? p.split(' ').map((v) => `${v * 10}px`).join(' ') : ''

  return (
    <div style={{ padding, ...style }}>{children}</div>
  )
}


export default Box
