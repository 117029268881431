import React, { Fragment, useEffect, useCallback } from 'react'
import { Button, Spin, notification } from 'antd'
import { useReducerState } from 'hooks'
import request from 'request'

import 'antd/dist/antd.css'

import { WidthContainer, Box } from 'components/layout'

import Header from './Header/Header'

import './App.scss'


notification.config({
  placement: 'bottomRight',
  bottom: 10,
  duration: 2.5,
})

const App = ({ children }) => {
  const [ state, setState ] = useReducerState({ user: null, error: null, envs: [], isFetching: true })
  const { user, error, isFetching, envs } = state

  useEffect(() => {
    request.get('/rest/auth')
      .then(({ data: user }) => {
        setState({ user, isFetching: false })
      }, (err) => {
        setState({ error: err, isFetching: false })
      })
  }, [])

  useEffect(() => {
    setState({ isLoading: true, envs: [] })

    request.get(`/rest/envs`)
      .then(({ data: { items } }) => {
        setState({
          isLoading: false,
          envs: items,
        })
      })
  }, [])

  if (isFetching) {
    return (
      <div style={{ padding: '30px 0', textAlign: 'center' }}>
        <Spin size="large" />
      </div>
    )
  }

  if (!user) {
    return (
      <WidthContainer>
        <Box p="3 0 3" style={{ textAlign: 'center' }}>
          <Button size="large" type="primary" href={`${globals.apiHost}/oidc/login`}>Login with Okta</Button>
        </Box>
      </WidthContainer>
    )
  }

  return (
    <Fragment>
      <Header user={user} envs={envs} />
      <Box p="3 0 3">
        <WidthContainer>
          {children}
        </WidthContainer>
      </Box>
    </Fragment>
  )
}


export default App
