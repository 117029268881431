import React, { Fragment, useContext, useState, useCallback, useEffect, useMemo } from 'react'
import { EnvStateContext } from 'helpers/contexts'
import { Input, Select, Icon } from 'antd'
import request from 'request'

import { Flex, Box } from 'components/layout'
import History from 'components/History/History'


const HistoryPage = () => {
  const env = useContext(EnvStateContext)
  const [ { isFetching, items }, setState ] = useState({ isFetching: true, items: [] })
  const [ searchQuery, setSearchQuery ] = useState('')
  const [ subjectFilter, setSubjectFilter ] = useState('all')

  useEffect(() => {
    setState({ isFetching: true, items: [] })

    request.get('/rest/history', {
      params: {
        env,
      },
    })
      .then(({ data: { items } }) => {
        setState({ items, isFetching: false })
      }, () => {
        setState({ items: [], isFetching: false })
      })
  }, [ env ])

  const handleSearchChange = useCallback((event) => {
    setSearchQuery(event.target.value)
  }, [])

  const handleSubjectSelect = useCallback((value) => {
    setSubjectFilter(value)
  }, [])

  const filteredItems = useMemo(() => {
    if (!searchQuery && subjectFilter === 'all') {
      return items
    }

    return items.filter(({ entityName, subject }) => (
      entityName
      && (!searchQuery || entityName.toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0)
      && (subjectFilter === 'all' || subject === subjectFilter)
    ))
  }, [ items, searchQuery, subjectFilter ])

  return (
    <Fragment>
      <Box p="0 0 2">
        <Flex alignCenter>
          <Input
            placeholder="Filter"
            prefix={<Icon type="filter" style={{ color: 'rgba(0,0,0,.25)' }} />}
            size="large"
            onChange={handleSearchChange}
          />
          <Box p="0 0 0 2">
            <Select
              size="large"
              placeholder="Project"
              defaultValue="all"
              value={subjectFilter}
              style={{ minWidth: 150 }}
              onChange={handleSubjectSelect}
            >
              <Select.Option key="all">All</Select.Option>
              <Select.Option key="feature">Features</Select.Option>
              <Select.Option key="user">Users</Select.Option>
              <Select.Option key="env">Environments</Select.Option>
              <Select.Option key="schedule">Schedule</Select.Option>
            </Select>
          </Box>
        </Flex>
      </Box>
      <History {...{ isFetching, items: filteredItems }} />
    </Fragment>
  )
}


export default HistoryPage
