import React, { Fragment, useContext, useState, useCallback, useEffect, useMemo } from 'react'
import { Input, Select, Icon, Pagination } from 'antd'
import request from 'request'

import { Flex, Box } from 'components/layout'
import WebhooksHistoryTable from 'components/WebhooksHistoryTable/WebhooksHistoryTable'


const WebhooksHistoryPage = ({ match: { params: { id } } }) => {
  const [ { isFetching, items, count }, setState ] = useState({ isFetching: true, items: [], count: 0 })
  const [ page, setPage ] = useState(1)
  const [ pageSize, setPageSize ] = useState(20)

  useEffect(() => {
    setState({ isFetching: true, items: [] })

    request.get('/rest/webhooks/history', {
      params: {
        offset: pageSize * (page - 1),
        limit: pageSize,
        webhook_id: id,
      },
    })
      .then(({ data: { items, count } }) => {
        setState({ items, count, isFetching: false })
      }, () => {
        setState({ items: [], count: 0, isFetching: false })
      })
  }, [ page, pageSize ])

  const handleChange = useCallback((page, pageSize) => {
    setPage(page)
    setPageSize(pageSize)
  }, [ page, pageSize ])


  return (
    <Fragment>
      <WebhooksHistoryTable {...{ isFetching, items }} />
      <Box p="2 0 0">
        <Flex flexEnd>
          <Pagination
            defaultPageSize={20}
            defaultCurrent={1}
            onChange={handleChange}
            total={count}
            pageSize={pageSize}
            showTotal={(total) => `Total ${total} items`}
          />
        </Flex>
      </Box>
    </Fragment>
  )
}


export default WebhooksHistoryPage
