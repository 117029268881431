const getUniqueTags = (items) => {
  const allItemsTags = items.reduce((arr, { tags }) => [
    ...arr,
    ...(tags || []),
  ], [])

  return [ ...new Set(allItemsTags) ]
}


export default getUniqueTags
