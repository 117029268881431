import React, { useState, useCallback, useMemo } from 'react'
import { Select, Input, Button, Icon, Checkbox } from 'antd'
import { Link, useLocation } from 'react-router-dom'

import { Flex, Box } from 'components/layout'


const useItemFilters = (props) => {
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const query = searchParams.get('search')

  const { tags = [], onCreateClick } = props

  const initialValues = useMemo(() => ({
    itemTagsVisibility: localStorage.getItem('itemTagsVisibility'),
  }), [])

  const [ searchQuery, setSearchQuery ] = useState(query)
  const [ filterTags, setFilterTags ] = useState([])
  const [ isItemTagsVisible, setItemTagsVisibility ] = useState(initialValues.itemTagsVisibility)

  const handleSearchChange = useCallback((event) => {
    setSearchQuery(event.target.value)
  }, [])

  const handleTagsFilterChange = useCallback((tags) => {
    setFilterTags(tags)
  }, [])

  const handleTagsVisibilityChange = useCallback((event) => {
    setItemTagsVisibility(event.target.checked)
    localStorage.setItem('itemTagsVisibility', event.target.checked)
  }, [])

  const itemFiltersNode = (
    <Box p="0 0 2">
      <Flex>
        <Box>
          <Flex>
            <Box p="0 1 0 0">
              <Input
                placeholder="Search by name"
                prefix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
                size="large"
                allowClear
                style={{ minWidth: '240px' }}
                onChange={handleSearchChange}
              />
            </Box>
            <Box p="0 1 0 0">
              <Select
                placeholder="Filter by tags"
                defaultValue={[]}
                mode="tags"
                size="large"
                style={{ minWidth: '180px' }}
                onChange={handleTagsFilterChange}
              >
                {
                  tags.map((tag) => (
                    <Select.Option key={tag}>{tag}</Select.Option>
                  ))
                }
              </Select>
              <Box p="1 0 0">
                <Checkbox checked={isItemTagsVisible} onChange={handleTagsVisibilityChange}>show tags</Checkbox>
              </Box>
            </Box>
          </Flex>
        </Box>
        <Box>
          <Button type="primary" icon="plus" size="large" onClick={onCreateClick}>Create</Button>
          <Box p="1 0 0" style={{ textAlign: 'right' }}>
            <Link to="/features/batch-remove" style={{ color: '#da0808' }}>batch remove</Link>
          </Box>
        </Box>
      </Flex>
    </Box>
  )

  return {
    itemFiltersNode,
    searchQuery,
    filterTags,
    isItemTagsVisible,
  }
}


export default useItemFilters
