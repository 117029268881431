import React from 'react'
import { NavLink } from 'react-router-dom'
import request from 'request'

import { WidthContainer } from 'components/layout'

import EnvSwitcher from './EnvSwitcher/EnvSwitcher'

import s from './Header.scss'


const logout = () => {
  request.get('/rest/logout')
    .then(() => {
      console.log('logout')
      window.location.replace('/')
    })
}

const menu = [
  { title: 'Feature Toggles', link: '/features' },
  { title: 'History', link: '/history' },
  { title: 'Webhooks', link: '/webhooks' },
  { title: 'Schedule', link: '/schedule' },
]

const Header = ({ user, envs }) => (
  <header className={s.header}>
    <EnvSwitcher envs={envs} />
    <WidthContainer>
      <div className={s.content}>
        <div className={s.menu}>
          {
            menu.map(({ title, link }, index) => (
              <NavLink
                key={index}
                className={s.link}
                activeClassName={s.active}
                to={link}
                exact
              >
                {title}
              </NavLink>
            ))
          }
        </div>
        <div>
          <div className={s.item}>
            <span className={s.userName}>[{user.name}]</span>
            <span onClick={logout}>Logout</span>
          </div>
        </div>
      </div>
    </WidthContainer>
  </header>
)


export default Header
