import React from 'react'


const EnvStateContext = React.createContext('stage')
const EnvDispatchContext = React.createContext('stage')


export {
  EnvStateContext,
  EnvDispatchContext,
}
