import React, { useMemo } from 'react'
import { Empty, Spin, Table, Tag, Icon, Tooltip } from 'antd'
import { Link } from 'react-router-dom'
import moment from 'moment-timezone'


const History = ({ isFetching, items }) => {
  const columns = useMemo(() => [
    {
      key: 'name',
      title: '',
      width: '10%',
      render: (_, record) => (
        <Link to={`/features?search=${record.featureName}`}>{record.featureName}</Link>
      ),
    },
    {
      key: 'action',
      title: 'Action',
      align: 'center',
      render: (_, { action, values }) => {
        let message
        let color

        if (action === 'enable') {
          message = 'Enabling'
          color = 'green'
        }
        else if (action === 'disable') {
          message = 'Disabling'
          color = 'magenta'
        }
        else {
          message = action
          color = null
        }

        return (
          <Tag color={color}>{message}</Tag>
        )
      },
    },
    {
      key: 'date',
      title: 'When',
      width: '30%',
      align: 'right',
      render: (_, { executeAt }) => (
        <Tooltip title={`${moment(executeAt).format('MM/DD/YYYY HH:mm:ss')} (Your local time); ${moment(executeAt).tz('America/New_York').format('MM/DD/YYYY HH:mm:ss (z)')}`}>
          {moment(executeAt).fromNow()}
        </Tooltip>
      ),
    },
  ], [])

  return (
    <div>
      {
        isFetching ? (
          <div style={{ padding: '30px 0', textAlign: 'center' }}>
            <Spin size="large" />
          </div>
        ) : (
          Boolean(items.length) ? (
            <Table columns={columns} dataSource={items} />
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )
        )
      }
    </div>
  )
}


export default History
