import React, { useMemo, useCallback, useEffect } from 'react'
import { Modal, Form, Alert } from 'antd'
import { required, startsWithCharacter } from 'helpers/validation'
import { Form as Formular } from 'formular'

import { Box } from 'components/layout'
import { Input, Select } from 'components/form'


const CreateModal = ({ tags, data, onSubmit, onCancel }) => {
  const isEditMode = Boolean(data)

  const form = useMemo(() => new Formular({
    fields: {
      project: {
        validate: [ required ],
        value: 'scentbird',
      },
      name: [ required, startsWithCharacter ],
      description: [ required ],
      issue: [ required ],
      tags: {
        validate: [ required ],
        value: [],
      },
    },
    initialValues: data || {},
  }), [])

  useEffect(() => {
    return () => {
      form.unsetValues()
    }
  }, [])

  const handleSubmit = useCallback(() => {
    form.submit()
      .then(() => {
        const values = form.getValues()

        onSubmit(data && data.id, values)
      })
  }, [ data, onSubmit ])

  const handleCancel = useCallback(() => {
    onCancel()
  }, [ onCancel ])

  return (
    <Modal
      title={data ? 'Edit Feature Toggle' : 'Create new Feature Toggle'}
      visible
      okText="Submit"
      onOk={handleSubmit}
      onCancel={handleCancel}
    >
      <Form layout="vertical" hideRequiredMark>
        {/*
        <Box p="0 0 2">
          <Select
            field={form.fields.project}
            placeholder="Project"
          >
            <Select.Option key="scentbird">Scentbird</Select.Option>
            <Select.Option key="dos">DOS</Select.Option>
          </Select>
        </Box>
        */}
        <Box p="0 0 2">
          <Input
            field={form.fields.name}
            placeholder="Name"
            disabled={isEditMode}
          />
        </Box>
        <Box p="0 0 2">
          <Input
            field={form.fields.description}
            placeholder="Description"
            multiline
            autosize={{ minRows: 2 }}
          />
        </Box>
        <Box p="0 0 2">
          <Input
            field={form.fields.issue}
            placeholder="JIRA Issue"
          />
        </Box>
        <Box p="0 0 2">
          <Select
            field={form.fields.tags}
            placeholder="Tags"
            mode="tags"
          >
            {
              tags.map((tag) => (
                <Select.Option key={tag}>{tag}</Select.Option>
              ))
            }
          </Select>
          <Box p="2 0 0">
            <Alert
              description={'use "notifyoff" tag to prevent notifications from being sent to Slack when changes are made'}
              type="warning"
            />
          </Box>
        </Box>
      </Form>
    </Modal>
  )
}


export default CreateModal
